import React from 'react';
import type { FC } from 'react';
import { css } from '@compiled/react';

import {
	CustomBlock,
	MetadataBlock,
	PreviewBlock,
	SnippetBlock,
	TitleBlock,
	ElementName,
	SmartLinkSize,
	SmartLinkTheme,
} from '@atlaskit/smart-card';
import { CardSSR as SmartCardSSR } from '@atlaskit/smart-card/ssr';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlaskit/platform-feature-flags';

import { CardSizes } from '../../linkCardsTypes';
import { EmojiRenderer } from '../EmojiRenderer';
import { useSmartCardEmoji } from '../useSmartCardEmoji';
import { useTrackedSmartCardState } from '../useTrackedSmartCardState';

import {
	floatingEmojiStyles as floatingEmojiStylesOld,
	getTitleBlockStyles,
	CommonCardWrapperStyles,
	MediumLargeCardWrapper as CardWrapper,
	getCustomImageUploadStyles,
} from './cardComponentsStyles';
import { CardErrorState } from './ErrorStates';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import type { CommonCardProps } from './commonCardProps';

const titleBlockUnauthorizedStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		display: 'none',
	},
});

const titleBlockStyles = css({
	marginTop: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		fontWeight: token('font.weight.medium'),
		color: token('color.text'),
		flexShrink: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:visited, a:focus, a:hover, a:active': {
		textDecoration: 'none',
		pointerEvents: 'auto',
	},
});

const customImageUploadStyles = css({
	marginLeft: 'calc(var(--container-gap-left) * -1)',
	marginRight: 'calc(var(--container-gap-right) * -1)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		marginTop: 'calc(var(--container-padding) * -1)',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		marginBottom: 'calc(var(--container-padding) * -1)',
	},
});

const floatingEmojiStyles = css({
	position: 'relative',
	top: token('space.negative.150', '-12px'),
	bottom: token('space.025', '2px'),
	height: '0px',
	overflow: 'visible',
});

export const LargeCard: FC<CommonCardProps> = ({
	contentId,
	cardId,
	link,
	title,
	imageSrc,
	cardHeight,
	isAvatarShown,
	isPublishDateShown,
	description,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	imageAltText,
	createAnalyticsEvent,
	...analyticsPassThroughProps
}) => {
	const {
		errorCardStatus,
		isNoResult,
		isRestricted,
		isUnauthorized,
		trackAndCategorizeError,
		trackSuccess,
	} = useTrackedSmartCardState(cardId, link, onCardSucceeded, onCardFailed);

	const { emojiId } = useSmartCardEmoji(link);
	const { showUnsplashImage, showExternalSiteImage, showCustomUploadedImage, hasImage } =
		getImageTypeToShow({
			imageSrc,
		});

	if (isRestricted || isNoResult)
		return (
			<CardErrorState
				isRestricted={isRestricted}
				isNoResult={isNoResult}
				link={link}
				cardId={cardId}
				cardSize={CardSizes.LARGE}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				errorStatus={errorCardStatus}
				createAnalyticsEvent={createAnalyticsEvent}
				{...analyticsPassThroughProps}
			/>
		);

	return fg('bandicoots-compiled-migration-smartcard') ? (
		<CommonCardWrapperStyles isInViewMode={isInViewMode}>
			<CardWrapper cardHeight={cardHeight} isLargeCard hasImage={hasImage}>
				<SmartCardSSR
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: isInViewMode,
						size: SmartLinkSize.XLarge,
					}}
					url={link}
					onResolve={trackSuccess}
					onError={trackAndCategorizeError}
				>
					{(showUnsplashImage || showExternalSiteImage) && (
						<PreviewBlock ignoreContainerPadding overrideUrl={imageSrc} />
					)}
					{showCustomUploadedImage && (
						<CustomBlock css={customImageUploadStyles}>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								imageAltText={imageAltText}
								shouldShowErrorMessage
							/>
						</CustomBlock>
					)}
					<CustomBlock css={floatingEmojiStyles} testId="smart-block-link-card-emoji-container">
						<EmojiRenderer emojiId={emojiId} />
					</CustomBlock>
					<TitleBlock
						hideIcon
						metadata={!!isAvatarShown ? [{ name: ElementName.OwnedByGroup }] : []}
						text={title}
						size={SmartLinkSize.XLarge}
						anchorTarget="_self"
						css={[titleBlockStyles, isUnauthorized && titleBlockUnauthorizedStyles]}
					/>
					{!!isPublishDateShown && <MetadataBlock primary={[{ name: ElementName.ModifiedOn }]} />}
					{!isUnauthorized && <SnippetBlock text={description} />}
				</SmartCardSSR>
			</CardWrapper>
		</CommonCardWrapperStyles>
	) : (
		<CommonCardWrapperStyles isInViewMode={isInViewMode}>
			<CardWrapper cardHeight={cardHeight} isLargeCard hasImage={hasImage}>
				<SmartCardSSR
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: isInViewMode,
						size: SmartLinkSize.XLarge,
					}}
					url={link}
					onResolve={trackSuccess}
					onError={trackAndCategorizeError}
				>
					{(showUnsplashImage || showExternalSiteImage) && (
						<PreviewBlock ignoreContainerPadding overrideUrl={imageSrc} />
					)}
					{showCustomUploadedImage && (
						<CustomBlock overrideCss={getCustomImageUploadStyles() as any}>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								imageAltText={imageAltText}
								shouldShowErrorMessage
							/>
						</CustomBlock>
					)}
					<CustomBlock
						overrideCss={floatingEmojiStylesOld as any}
						testId="smart-block-link-card-emoji-container"
					>
						<EmojiRenderer emojiId={emojiId} />
					</CustomBlock>
					<TitleBlock
						hideIcon
						metadata={!!isAvatarShown ? [{ name: ElementName.OwnedByGroup }] : []}
						text={title}
						size={SmartLinkSize.XLarge}
						anchorTarget="_self"
						overrideCss={getTitleBlockStyles({ isUnauthorized }) as any}
					/>
					{!!isPublishDateShown && <MetadataBlock primary={[{ name: ElementName.ModifiedOn }]} />}
					{!isUnauthorized && <SnippetBlock text={description} />}
				</SmartCardSSR>
			</CardWrapper>
		</CommonCardWrapperStyles>
	);
};
