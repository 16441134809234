import type { FC } from 'react';
import React from 'react';
import { css } from '@compiled/react';

import {
	ElementName,
	MediaPlacement,
	PreviewBlock,
	SmartLinkSize,
	SmartLinkTheme,
	TitleBlock,
	CustomBlock,
} from '@atlaskit/smart-card';
import { CardSSR as SmartCardSSR } from '@atlaskit/smart-card/ssr';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlaskit/platform-feature-flags';

import { CardSizes } from '../../linkCardsTypes';
import { useTrackedSmartCardState } from '../useTrackedSmartCardState';

import { CardErrorState } from './ErrorStates';
import {
	CommonCardWrapperStyles,
	getTitleBlockStyles,
	EmptyAndSmallCardWrapper as SmallCardWrapper,
	getCustomImageUploadStyles,
} from './cardComponentsStyles';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import type { CommonCardProps } from './commonCardProps';

type SmallCardProps = CommonCardProps & {
	isClickableContainer?: boolean;
};

const titleBlockImagePaddingStyles = css({
	padding: token('space.150', '12px'),
});

const titleBlockUnauthorizedStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		display: 'none',
	},
});

const titleBlockStyles = css({
	marginTop: token('space.0', '0px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		fontWeight: token('font.weight.medium'),
		color: token('color.text'),
		flexShrink: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:visited, a:focus, a:hover, a:active': {
		textDecoration: 'none',
		pointerEvents: 'auto',
	},
});

const customImageUploadStyles = css({
	position: 'absolute',
	width: '72px',
	top: token('space.0', '0px'),
	bottom: token('space.0', '0px'),
	left: token('space.0', '0px'),
});

export const SmallCard: FC<SmallCardProps> = ({
	contentId,
	cardId,
	link,
	title,
	imageSrc,
	isAvatarShown,
	isPublishDateShown,
	cardHeight,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	isClickableContainer,
	imageAltText,
	createAnalyticsEvent,
	...analyticsPassThroughProps
}) => {
	const {
		errorCardStatus,
		isNoResult,
		isRestricted,
		isUnauthorized,
		trackAndCategorizeError,
		trackSuccess,
	} = useTrackedSmartCardState(cardId, link, onCardSucceeded, onCardFailed);

	const { showUnsplashImage, showExternalSiteImage, showCustomUploadedImage, hasImage } =
		getImageTypeToShow({
			imageSrc,
		});

	if (isRestricted || isNoResult)
		return (
			<CardErrorState
				isRestricted={isRestricted}
				isNoResult={isNoResult}
				link={link}
				cardId={cardId}
				cardSize={CardSizes.SMALL}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				errorStatus={errorCardStatus}
				createAnalyticsEvent={createAnalyticsEvent}
				{...analyticsPassThroughProps}
			/>
		);

	return fg('bandicoots-compiled-migration-smartcard') ? (
		<CommonCardWrapperStyles isInViewMode={isInViewMode}>
			<SmallCardWrapper
				size={CardSizes.SMALL}
				cardHeight={cardHeight}
				isPublishDateShown={isPublishDateShown}
				hasImage={hasImage}
				isAvatarShown={isAvatarShown}
			>
				<SmartCardSSR
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: isClickableContainer ?? isInViewMode,
						size: SmartLinkSize.Large,
					}}
					url={link}
					onResolve={trackSuccess}
					onError={trackAndCategorizeError}
				>
					{(showUnsplashImage || showExternalSiteImage) && (
						<PreviewBlock
							ignoreContainerPadding
							placement={MediaPlacement.Left}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage && (
						<CustomBlock css={customImageUploadStyles}>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								imageAltText={imageAltText}
							/>
						</CustomBlock>
					)}
					<TitleBlock
						hideIcon
						text={title}
						maxLines={1}
						anchorTarget="_self"
						css={[
							titleBlockStyles,
							hasImage && titleBlockImagePaddingStyles,
							isUnauthorized && titleBlockUnauthorizedStyles,
						]}
						{...(!!isAvatarShown && { metadata: [{ name: ElementName.OwnedByGroup }] })}
						{...(!!isPublishDateShown && { subtitle: [{ name: ElementName.ModifiedOn }] })}
					/>
				</SmartCardSSR>
			</SmallCardWrapper>
		</CommonCardWrapperStyles>
	) : (
		<CommonCardWrapperStyles isInViewMode={isInViewMode}>
			<SmallCardWrapper
				size={CardSizes.SMALL}
				cardHeight={cardHeight}
				isPublishDateShown={isPublishDateShown}
				hasImage={hasImage}
				isAvatarShown={isAvatarShown}
			>
				<SmartCardSSR
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: isClickableContainer ?? isInViewMode,
						size: SmartLinkSize.Large,
					}}
					url={link}
					onResolve={trackSuccess}
					onError={trackAndCategorizeError}
				>
					{(showUnsplashImage || showExternalSiteImage) && (
						<PreviewBlock
							ignoreContainerPadding
							placement={MediaPlacement.Left}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage && (
						<CustomBlock overrideCss={getCustomImageUploadStyles(MediaPlacement.Left) as any}>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								imageAltText={imageAltText}
							/>
						</CustomBlock>
					)}
					<TitleBlock
						hideIcon
						text={title}
						maxLines={1}
						anchorTarget="_self"
						overrideCss={
							getTitleBlockStyles({
								hasImage,
								isSmallCard: true,
								isUnauthorized,
							}) as any
						}
						{...(!!isAvatarShown && { metadata: [{ name: ElementName.OwnedByGroup }] })}
						{...(!!isPublishDateShown && { subtitle: [{ name: ElementName.ModifiedOn }] })}
					/>
				</SmartCardSSR>
			</SmallCardWrapper>
		</CommonCardWrapperStyles>
	);
};
